.slide{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
  height: fit-content;
}

.seccionSlide{
  width: stretch;
  height: stretch;
}

.imagenSlide{
  width: stretch;
  height: 800px;
}

.carousel-indicators [data-bs-target] { /* permite modificar el indicador del slider que por defecto viene como una linea horizontal, además los !important se colocan para que los cambios se realicen por encima de la configuración por defecto*/
  width: 15px;
  height: 15px;
  border: 1px solid rgba(0, 0, 0, .25);
  border-radius: 25px;
  margin: 5px;
}

.carousel-control-prev-icon {
  height: 100px !important;
  width: 100px !important;
}
.carousel-control-next-icon {
  height: 100px !important;
  width: 100px !important;
}

/* Resolucion menor a 1024px */
@media screen and (max-width:1024px) {
  .slide{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center; /* alinear verticalmente */
    justify-content: center; /* alinear horizontalmente */
    height: fit-content;
  }
  
  .seccionSlide{
    width: stretch;
    height: stretch;
  }
  
  .imagenSlide{
    width: stretch;
    height: 475px;
  }
}

@media screen and (max-width:600px) {
  .imagenSlide{
    width: stretch;
    height: 320px;
  }
}