.filtro{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}

.seccionCatalogoFiltrado{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center; /* alinear verticalmente */
  justify-content: space-evenly; /* alinear horizontalmente */
  text-align: center;
  width: 80%;
}

.botonVerOcultar{
  background-color: transparent;
  border: 3px double crimson;
  border-radius: 15px;
}

.seccionFiltrosEnMedio{
  display: none;
}

.seccionFiltros{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center; /* alinear verticalmente */
  justify-content: flex-start; /* alinear horizontalmente */
  text-align: center;
  width: 20%;
  padding: 90px 30px 0 30px;
}

.areaTituloFiltro{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center; /* alinear verticalmente */
  justify-content: space-evenly; /* alinear horizontalmente */
  text-align: center;
  width: -webkit-fill-available;
}

.areaCatalogoFiltrado{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start; /* alinear verticalmente */
  justify-content: space-evenly; /* alinear horizontalmente */
  text-align: center;
}

.linkSubcategoria{
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 18px;
  margin: 3px 0 3px 0;
}
.linkSubcategoria:hover{
  color: crimson;
  text-decoration: underline;
}

.lineaSeparatoria{
  width: -webkit-fill-available;
  height: 3px;
  color: black;
  background-color: black;
  opacity: 100%;
}

.productoCatalogoFiltrado{
  margin: 2em;
}

.imagenCatalogoFiltrado{
  height: 250px;
  width: 250px;
  border-radius: 25px;
  background-color: white;
}

.nombreProductoCatalogoFiltrado{
  font-size: 28px;
  width: 250px;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.botonCatalogoFiltrado{
  background-color: crimson;
  color: antiquewhite;
  font-weight: bolder;
  font-size: 16px;
  border-radius: 15px;
  height: 50px;
  width: 150px;
}

@media screen and (max-width:768px) {
  .seccionFiltros{
    display: none;
  }
  .seccionFiltrosEnMedio{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center; /* alinear verticalmente */
    justify-content: flex-start; /* alinear horizontalmente */
    text-align: center;
  }
  .imagenCatalogoFiltrado{
    height: 200px;
    width: 200px;
  }
  .nombreProductoCatalogoFiltrado{
    width: 200px;
    height: 150px;
  }
}