.contacto{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
  height: fit-content;
  background-image: radial-gradient(rgba(220, 20, 60, 0.25), rgba(255, 240, 245, 0.5));
  margin: 50px 0 0 0;
}

.areaContacto{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
  margin: 60px 0 30px 0;
}

.escritoContacto{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
}

.seccionContacto{
  text-align: left;
  width: 435px;
  margin: 0 25px 0 0;
}

.tituloContacto{
  font-size: 28px;
  margin: 0 0 20px 0;
}

.parrafoContacto{
  font-size: 18px;
  text-align: justify;
  margin: 20px 0 0 0;
}

.formularioContacto{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 375px;
  height: 560;
  margin: 0 37.5px 0 25px;
}

.textosPlanos{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 375px;
}

.ingresoTexto{
  height: 40px;
  width: 375px;
  border-radius: 7.5px;
}

.ingresoTexto::-webkit-outer-spin-button,
.ingresoTexto::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Permite quitar las flechas que aparecen al tener un input de numero */
}

.mensaje{
  height: 115px;
  width: 375px;
  text-align: justify;
  resize: none;
  border-radius: 7.5px;
}

.reCaptcha{
  margin: 15px 0 15px 0;
}

.botonEnvio{
  height: 40px;
  width: 375px;
  background-color: red;
  color: antiquewhite;
  border-radius: 5px;
}

.seccionImagen{
  width: 850px;
  height: 530px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 37.5px;
}

.imagenContacto{
  width: -webkit-fill-available;
  height: fit-content;
  border-radius: 100px;
}

.seccionImagenMetida{
  display: none;
}

@media screen and (max-width:1785px) {
  .seccionImagen{
    width: 500px;
  }
}

@media screen and (max-width:1440px) {
  .seccionImagen{
    display: none;
  }
  .parrafoContacto{
    margin: 20px 0 20px 0;
  }
  .seccionImagenMetida{
    display: flex;
    align-items: center;
    width: 430px;
    height: 265px;
    margin: 20px 0 0 0;
  }
  .imagenContactoMetida{
    width: -webkit-fill-available;
    height: fit-content;
    border-radius: 50px;
  }
}

@media screen and (max-width:897px) {
  .areaContacto{
    flex-direction: column;
  }
  .seccionContacto{
    margin: 0;
  }
  .formularioContacto{
    margin: 0 37.5px 20px 25px;
  }
  .seccionImagenMetida{
    display: none;
  }

  .seccionImagen{
    display: flex;
    width: 400px;
    height: 250px;
    margin: 0;
  }
}

@media screen and (max-width:450px) {
  .areaContacto{
    align-items: center;
  }
  .seccionContacto{
    width: 300px;
  }
  .formularioContacto{
    margin: 0;
    width: -webkit-fill-available;
  }
  .textosPlanos{
    width: 300px;
  }
  .ingresoTexto{
    width: 300px;
  }
  .mensaje{
    width: 300px;
  }
  .botonEnvio{
    width: 300px;
  }
  .seccionImagen{
    width: 300px;
    height: 187.5px;
  }
}