.areaProducto{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
}

.seccionProductoVertical{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  align-items: center; /* alinear verticalmente */
  justify-content: space-evenly; /* alinear horizontalmente */
  text-align: center;
  width: fit-content;
}

.seccionProductoHorizontal{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  align-items: center; /* alinear verticalmente */
  justify-content: space-evenly; /* alinear horizontalmente */
  text-align: center;
  width: -webkit-fill-available;
  border-radius: 150px;
}

.seccionVisualProducto{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}

.iconoVolverAtras{
  height: 50px;
  width: 50px;
  color: crimson;
}

.tamannoImagen{
  height: 400px;
  width: 400px;
  border-radius: 150px;
  background-color: white;
  margin: 25px 0 25px 0;
}

.seccionProducto{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  height: fit-content;
}

.seccionInformacionProducto{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: flex-start;
  height: fit-content;
}

.nombreProducto{
  height: fit-content;
  width: 350px;
  font-size: 56px;
  font-weight: bold;
  margin: 25px 0 25px 0;
}

.codigoProducto{
  font-size: 43px;
  font-weight: bold;
}

.botonCotizar{
  color: white;
  font-size: 16px;
  font-weight: bold;
  background-color: crimson;
  border-radius: 50px;
  width: 200px;
  height: 50px;
  margin: 15px 0 0 0;
}

.seccionDescripcion{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 50%;
}

.descripcionProducto{
  font-size: 24px;
  text-align: justify;
  margin: 25px 75px 25px 75px;
  border: 0.5px groove rgba(220, 20, 60, 0.125);
  border-radius: 125px;
  padding: 1.5em;
}

.seccionFichaTecnica{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 30%;
}

.cuadrosTablaFichaTecnica{
  border-top: 1.5px solid slategrey;
  border-bottom: 1.5px solid slategrey;
}

.bordeIntermedioTablaFichaTecnica{
  border-right: 1.5px solid slategrey;
  width: 30%;
}

.tablaFichaTecnica{
  font-size: 18px;
  font-weight: 350;
}

@media screen and (max-width:768px) {
  .tamannoImagen{
    margin: 25px 0 25px 0;
    width: 300px;
    height: 300px;
    border-radius: 75px;
  }
  .nombreProducto{
    margin: 25px 0 25px 0;
    padding: 50px 0 0 0;
    width: 300px;
    font-size: 48px;
  }
  .botonCotizar{
    margin: 0 0 25px 0
  }
  .descripcionProducto{
    font-size: 16px;
    margin: 0;
    border: 0.5px groove rgba(220, 20, 60, 0.125);
    border-radius: 50px;
    padding: 1.5em;
  }
}
@media screen and (max-width:599px) {
  .nombreProducto{
    padding: 0;
  }
  .seccionInformacionProducto{
    display: flex;
    flex-direction: column;
  }
  .seccionDescripcion{
    width: 300px;
    margin: 0 0 7.5px 0;
  }
  
  .seccionFichaTecnica{
    width: 300px;
    margin: 7.5px 0 0 0;
  }
}