.muestra{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
  height: fit-content;
}

.seccionMuestra{
  display: flex;
  flex-direction: row;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
}

.areaMuestra{
  display: flex;
  flex-direction: row;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
  width: 575px;
  height: 250px;
  margin: 42.5px 12.5px 50px 12.5px;
}

.lineaMuestra{
  width: 45px;
  margin: 10px;
  height: 250px;
  transform: skew(-20deg); /* Transforma la forma del fondo con la identificación de la posición necesaria x o y*/
  border: 5px;
  border-color: indianred;
  border-style: groove;
  border-radius: 1.5px;
}

.fondoMuestra{
  display: flex;
  flex-direction: column;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
  width: 450px;
  height: 250px;
  margin: 25px;
  transform: skew(-20deg); /* Transforma la forma del fondo con la identificación de la posición necesaria x o y*/
  border: 5px;
  border-color: indianred;
  border-style: groove;
  border-radius: 1.5px;
}

.textoMuestra{
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  transform: skew(20deg) !important; /* Transforma la forma del fondo con la identificación de la posición necesaria x o y*/
  width: 250px;
  height: 70px;
  margin: 70px 0 10px 0;
}

.botonMuestra{
  width: 135px;
  height: 40px;
  border-radius: 7.5px;
  transform: skew(20deg) !important; /* Transforma la forma del fondo con la identificación de la posición necesaria x o y*/
  background-color: crimson;
  color: antiquewhite;
  margin: 10px 0 60px 0;
}

@media screen and (max-width:768px) {
  .areaMuestra{
    height: auto;
    width: auto;
  }
  .lineaMuestra{
    width: 30px;
    height: 166.67px;
  }
  .fondoMuestra{
    width: 300px;
    height: 166.67px;
  }
}

@media screen and (max-width:475px) {
  .areaMuestra{
    margin: 0;
  }
  .lineaMuestra{
    width: 20px;
    height: 111px;
    margin: 7.5px;
  }
  .fondoMuestra{
    width: 200px;
    height: 111px;
    margin: 7.5px;
  }
  .textoMuestra{
    font-size: 16px;
    width: auto;
    height: auto;
    margin: 95px 0 5px 0;
  }
  .botonMuestra{
    font-size: 12px;
    width: 75px;
    height: 22.22px;
    margin: 5px 0 95px 0;
  }
}