.footer{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center; /* alinear verticalmente */
  justify-content: space-around; /* alinear horizontalmente */
  padding: 90px 0 100px 0;
  background-color: rosybrown;
}

.seccionEmpresa{
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center; /* alinear verticalmente */
  justify-content: space-evenly; /* alinear horizontalmente */
  width: 745px;
  height: 260px;
}

.linkLogoFooter{
  margin: 0 0 60px 0;
}

.logoFooter{
  height: 60px;
  width: auto;
}

.areaLinks{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center; /* alinear verticalmente */
  justify-content: space-evenly; /* alinear horizontalmente */
  width: stretch;
}
.seccionLinks{
  margin: 0.5em;
}

.link{
  text-align: center;
  font-size: 14px;
  color: black;
  text-decoration: none;
  font-weight: bold;
}

.botonLink{
  text-align: center;
  font-size: 14px;
  color: black;
  text-decoration: none;
  font-weight: bold;
  background-color: transparent;
  border: transparent;
}

.lineaSeparacion{
  height: 2.5px;
  color: black;
  background-color: black;
  opacity: 100%;
  width: 745px;
}

.seccionRedesSociales{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 200px;
  height: 60px;
}

.iconoRedSocial{
  height: 35px;
  width: 35px;
  color: black;
}

.seccionInformacion{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center; /* alinear verticalmente */
  justify-content: space-around; /* alinear horizontalmente */
  width: 900px;
  height: 300px;
}

.areaInformacion{
  display: flex;
  flex-direction: column;
  align-items: start; /* alinear verticalmente */
  justify-content: space-between; /* alinear horizontalmente */
  height: 260px;
  width: 325px;
}

.textoInformacion{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 310px;
  height: 30px;
}

.espacioIcono{
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconos{
  color: black;
  height: stretch;
  width: stretch;
}

.escritoInformacion{
  margin: 0 0 0 15px !important;
  font-size: 14px;
  color: black;
  text-align: justify;
}

.mail{
  margin: 0 0 0 15px;
  color: black;
  text-decoration: none;
}

.cuadroMapa{
  width: 488.886px;
  height: 300px;
  border: 0;
}

.mapa{
  width: 489px;
  height: 300px;
}

.cuadroMapaAbajo{
  display: none;
}

.mapaAbajo{
  display: none;
}

@media screen and (max-width:1645px) {
  .seccionEmpresa{
    margin: 0 0 35px 0;
  }
}

@media screen and (max-width:920px) {
  .seccionInformacion{
    flex-direction: column;
  }
  .cuadroMapa{
    display: none;
  }
  .cuadroMapaAbajo{
    display: flex;
    width: 300px;
    height: 187px;
    border: 0;
  }
  .mapa{
    display: none;
  }
  .mapaAbajo{
    display: flex;
    margin: 30px 0 0 0;
  }
}

@media screen and (max-width:745px) {
  .seccionEmpresa{
    height: fit-content;
    margin: 0 0 15px 0;
  }
  .linkLogoFooter{
    margin: 0 0 7.5px 0;
  }
  .areaLinks{
    flex-direction: column;
    height: 300px;
    margin: 7.5px 0 15px 0;
  }
  .lineaSeparacion{
    display: none;
  }
  .areaInformacion{
    width: 300px;
  }
  .textoInformacionArriba{
    width: 300px;
  }
  .textoInformacion{
    width: 300px;
  }
  .textoInformacionAbajo{
    width: 300px;
  }
}