.header{
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
  height: 200px;
}

.seccionLogo{
  width: 500px;
  display: flex;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
}

.logoHeader{
  height: 150px;
  width: 300px;
}

.paginas{
  width: 1215px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.linkNavegacion{
  font-size: 24px;
  color: black;
  font-weight: bold;
  text-decoration: none;
  width: fit-content;
  height: 100px;
  display: flex;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
}

.linkNavegacion:hover{
  color: crimson;
  text-decoration: underline crimson;
}

.botonDropdown{
  font-size: 24px !important;
  color: black !important;
  font-weight: bold !important;
  text-decoration: none !important;
  width: fit-content;
  height: 100px;
  display: flex;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
  background-color: transparent;
  border: transparent;
}
.botonDropdown:hover{
  color: crimson !important;
  text-decoration: underline crimson !important;
}

.menuDropdown{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.menuDropdown:hover{
  color: crimson;
  text-decoration: underline crimson;
}

.botonLinkNavegacion{
  font-size: 24px;
  color: black;
  font-weight: bold;
  text-decoration: none;
  width: fit-content;
  height: 100px;
  display: flex;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
  background-color: transparent;
  border: transparent;
}

.botonLinkNavegacion:hover{
  color: crimson;
  text-decoration: underline crimson;
}

.seccionMenu{
  display: none;
}

.botonMenuContacto{
  width: -webkit-fill-available;
  background-color: transparent;
  border: transparent;
}

.cuadroBusqueda{
  width: 175px;
  display: flex;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
}

.busqueda{
  height: 30px;
  width: 30px;
}

.busqueda:hover{
  color: crimson;
}

.dropdownMenuDesplegable{
  padding: 0 75px 0 0 !important;
  background-color: transparent !important;
  border: none !important;
}

.menuBusquedaDesplegable{
  width: 400px;
  text-align: center !important;
  display: flex !important;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  --bs-dropdown-link-active-bg: lavenderblush !important;
}

.barraBusqueda{
  width: 400px;
  height: 45px;
  font-size: 24px;
  border: 2.5px solid;
  border-radius: 5px;
  background-color: whitesmoke;
  text-align: center;
}

.itemsProductosEncontrados{
  background-color: whitesmoke !important;
  border: 2.5px solid whitesmoke !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.imagenProductoEncontrado{
  height: 75px;
  width: 75px;
}

.linkProductosEncontrados{
  text-align: center;
  color: black;
  text-decoration: none;
  font-size: 24px;
  width: 300px !important;
  text-wrap: pretty;
  margin: 0 0 0 15px;
}

.linkProductosEncontrados:hover{
  color: crimson;
  font-weight: bold;
  text-decoration: underline crimson;
}

/* Ancho de la resolución de pantalla menor a 1134px */

@media screen and (max-width: 1134px){

  .paginas{
    display: none;
  }
  .cuadroBusqueda{
    display: none;
  }
  
  .seccionLogo{
    width: 60%;
    display: flex;
    align-items: center; /* alinear verticalmente */
    justify-content: center; /* alinear horizontalmente */
  }

  .logoHeader{
    height: 150px;
    width: 300px;
  }

  .seccionMenu{
    width: 40%;
    display: flex;
    align-items: center; /* alinear verticalmente */
    justify-content: center; /* alinear horizontalmente */
  }

  .menu{
    height: 100px;
    width: 120px;
    color: crimson;
  }

  .seccionNavegacion{
    height:100px;
    width: stretch;
    display: flex;
    align-items: center; /* alinear verticalmente */
    justify-content: center; /* alinear horizontalmente */
  }

  .iconoLink{
    height: 50px;
    width: 50px;
    color: crimson;
  }
}

@media screen and (max-width:500px) {
  .logoHeader{
    width: 150px;
    height: 75px;
  }
  .menu{
    width: 75px;
    height: 75px;
  }
}