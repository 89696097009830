.paginaNosotros{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
  height: fit-content;
}

.areaQuienMision{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start; /* alinear verticalmente */
  justify-content: space-evenly; /* alinear horizontalmente */
  margin: 0 0 20px 0;
}

.seccionQuienMision{
  width: 900px;
  height: fit-content;
  background-color: rgba(250, 128, 114, 0.5);
  border-radius: 50px;
  margin: 0 12.5px 0 12.5px;
}

.imagenQuienMision{
  width: -webkit-fill-available;
  height: 625px;
  border-radius: 50px 50px 0  0; /* va de arriba a abajo de izquierda a derecha  */
}

.tituloQuienMision{
  font-size: 34px;
  text-align: start;
  margin: 35px 50px 20px 30px;
}

.escritoQuienMision{
  font-size: 24px;
  text-align: justify;
  margin: 20px 50px 70px 30px;
}

@media screen and (max-width: 1850px) {
  .areaQuienMision{
    margin: 0;
  }

  .seccionQuienMision{
    margin: 0 12.5px 20px 12.5px;
  }
}

@media screen and (max-width:1024px) {
  .seccionQuienMision{
    width: 650px;
  }

  .imagenQuienMision{
    height: 450px;
  }
}

@media screen and (max-width:768px) {
  .seccionQuienMision{
    width: 500px;
  }

  .imagenQuienMision{
    height: 275px;
  }

  .tituloQuienMision{
    font-size: 30px;
  }

  .escritoQuienMision{
    font-size: 20px;
  }
}

@media screen and (max-width:525px) {
  .seccionQuienMision{
    width: 300px;
  }

  .imagenQuienMision{
    height: 200px;
  }
  .tituloQuienMision{
    font-size: 24px;
  }

  .escritoQuienMision{
    font-size: 16px;
  }
}