.categorias{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
}

.seccionDescripcionCategoria{
  width: -webkit-fill-available;
}

.esloganHSM{
  text-align: center;
  font-size: 28px;
  margin: 40px 0 30px 0;
}

.areaCategoria{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start; /* alinear verticalmente */
  justify-content: space-evenly; /* alinear horizontalmente */
}

.seccionCategoria{
  margin: 30px 7.5px 30px 7.5px;
}

.seccionCategoria:hover{
  background-color:  rgba(220, 20, 60, 0.34);
  border-radius: 25px;
}

.linkCategoria{
  text-decoration: none;
}

.imagenCategoria{
  width: 275px;
  height: 275px;
  border-radius: 25px;
  background-color: white;
}

.nombreCategoria{
  color: black;
  text-align: center;
  width: 275px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;  
}