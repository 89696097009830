.areaCatalogo{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.seccionFiltroCategoria{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 20%;
  padding: 30px;
}
.seccionFiltroCategoriaMedio{
  display: none;
}

.botonFiltro{
  background-color: transparent;
  border: 3px double crimson;
  border-radius: 15px;
}

.linkCategoria{
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 18px;
  margin: 3px 0 3px 0;
  text-align: center;
}

.lineaSeparatoria{
  width: -webkit-fill-available;
  height: 3px;
  color: black;
  background-color: black;
  opacity: 100%;
}

.seccionCatalogo{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center; /* alinear verticalmente */
  justify-content: space-evenly; /* alinear horizontalmente */
  text-align: center;
  width: 80%;
}

.seccionProductos{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.productoCatalogo{
  margin: 2em;
}

.imagenCatalogo{
  height: 250px;
  width: 250px;
  border-radius: 25px;
  background-color: white;
}

.nombreProductoCatalogo{
  font-size: 28px;
  width: 250px;
  height: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.botonCatalogo{
  background-color: crimson;
  color: antiquewhite;
  font-weight: bolder;
  font-size: 16px;
  border-radius: 15px;
  height: 50px;
  width: 150px;
}

@media screen and (max-width:1024px) {
  .seccionCatalogo{
    flex-direction: column;
    align-items: center;
  }
  .seccionFiltroCategoria{
    display: none;
  }
  .seccionProductos{
    width: -webkit-fill-available;
  }
  .seccionFiltroCategoriaMedio{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
}

@media screen and (max-width:425px) {
  .imagenCatalogo{
    height: 250px;
    width: 250px;
  }
  .nombreProductoCatalogo{
    width: 250px;
    height: 150px;
  }
}