.nosotros{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
  height: fit-content;
}

.sobreNosotros{
  width: stretch;
}

.tituloNosotros{
  text-align: center;
  font-size: 28px;
  margin: 50px 0 25px 0;
}

.areaNosotros{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center; /* alinear verticalmente */
  justify-content: center; /* alinear horizontalmente */
}

.seccionNosotros{
  margin: 25px 7.5px 42.5px 7.5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.imagenNosotros{
  width: 425px;
  height: 425px;
  border-radius: 50px;
  background-color: lightsteelblue;
  margin-bottom: 20px;
}

.iconoNumero{
  height: 45px;
  width: 45px;
  color: crimson;
  margin: 20px 0 12.5px 0;
}

.textoNosotros{
  width: 400px;
  height: max-content;
  text-align: center;
  font-size: 20px;
  margin: 12.5px 0 0 0;
}

.areaNosotrosSlide{
  display: none;
}

.espacioNosotrosPequenno{
  display: none;
}

@media screen and (max-width: 880px) {
  .areaNosotros{
    display: none;
  }
  .areaNosotrosSlide{
    display: flex;
    width: -webkit-fill-available;
    text-align: center;
  }
  .iconoNumero{
    width: 400px;
  }
  .slideNosotros{
    width: 400px;
    height: 400px;
  }
  .seccionTextoNosotrosSlide{
    display: flex;
    justify-content: center;
  }
  .textoNosotros{
    width: 400px;
  }
}

@media screen and (max-width:425px) {
  .iconoNumero{
    width: 300px;
  }
  .slideNosotros{
    width: 300px;
    height: 300px;
  }
  .textoNosotros{
    width: 300px;
  }
  .espacioNosotrosPequenno{
    display: flex;
  }
}